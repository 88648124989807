import { Brands } from '../Brand';
import { useLayoutContext } from '../layout/Layout';
import { KeyValuePair } from './KeyValuePair';
import { PROVINCE_LIST_ITEMS, STATE_LIST_ITEMS } from './ListItemHelper';
import { useGraphQL } from './useGraphQL';
import { MaskType } from './Textbox';

interface ILocalization {
    checking: string;
    company: string;
    customerRelationsFax: string;
    customerRelationsPhone: string;
    customerServiceEmail: string;
    customerServiceFax: string;
    customerServicePhone: string;
    consumerPrivacyEmail: string;
    consumerPrivacyFax: string;
    consumerPrivacyPhone: string;
    incomeVerificationEmail: string;
    isCashMoney: boolean;
    isLendDirect: boolean;
    isSpeedyCash: boolean;
    shortDateFormat: string;
    signIn: string;
    signOut: string;
    socialSecurityNumber: string;
    state: string;
    stateListItems: Readonly<KeyValuePair<string, string>[]>;
    nonRestrictedStateListItems: Readonly<KeyValuePair<string, string>[]>;
    zip: string;
    zipMask: MaskType;
}

const USA: ILocalization = {
    checking: 'Checking',
    company: 'Speedy Cash',
    customerRelationsFax: '1-316-771-8801',
    customerRelationsPhone: '1-800-856-2911',
    customerServiceEmail: 'customerservice@speedycash.com',
    customerServiceFax: '1-888-333-0568',
    customerServicePhone: '1-888-333-1360',
    consumerPrivacyEmail: 'privacy@speedycash.com',
    consumerPrivacyFax: '',
    consumerPrivacyPhone: '',
    incomeVerificationEmail: 'Verify@SpeedyCash.com',
    isCashMoney: false,
    isLendDirect: false,
    isSpeedyCash: true,
    shortDateFormat: 'M/d/yyyy',
    signIn: 'Sign in',
    signOut: 'Sign out',
    socialSecurityNumber: 'Social Security Number',
    state: 'State',
    stateListItems: STATE_LIST_ITEMS,
    nonRestrictedStateListItems: [],
    zip: 'Zip',
    zipMask: 'zip-code',
};

const CM_CANADA: ILocalization = {
    checking: 'Chequing',
    company: 'Cash Money',
    customerRelationsFax: '1-416-213-1139',
    customerRelationsPhone: '1-877-857-7805',
    customerServiceEmail: 'customerservice@cashmoney.ca',
    customerServiceFax: '1-855-493-8101',
    customerServicePhone: '1-877-526-6639',
    consumerPrivacyEmail: 'privacy@cashmoney.ca',
    consumerPrivacyFax: '1-877-293-0311',
    consumerPrivacyPhone: '1-416-213-1113',
    incomeVerificationEmail: 'Verify@SpeedyCash.com',
    isCashMoney: true,
    isLendDirect: false,
    isSpeedyCash: false,
    shortDateFormat: 'dd/MM/yyyy',
    signIn: 'Log in',
    signOut: 'Log Out',
    socialSecurityNumber: 'Social Insurance Number',
    state: 'Province',
    stateListItems: PROVINCE_LIST_ITEMS,
    nonRestrictedStateListItems: [],
    zip: 'Postal code',
    zipMask: 'canada-postal-code',
};

const LD_CANADA: ILocalization = {
    checking: 'Chequing',
    company: 'Lend Direct',
    customerRelationsFax: '1-416-213-1139',
    customerRelationsPhone: '1-877-857-7805',
    customerServiceEmail: 'customerservice@lenddirect.ca',
    customerServiceFax: '1-855-775-8225',
    customerServicePhone: '1-855-630-5363',
    consumerPrivacyEmail: 'privacy@cashmoney.ca',
    consumerPrivacyFax: '1-877-293-0311',
    consumerPrivacyPhone: '1-416-213-1113',
    incomeVerificationEmail: 'Verify@SpeedyCash.com',
    isCashMoney: false,
    isLendDirect: true,
    isSpeedyCash: false,
    shortDateFormat: 'dd/MM/yyyy',
    signIn: 'Log in',
    signOut: 'Log Out',
    socialSecurityNumber: 'Social Insurance Number',
    state: 'Province',
    stateListItems: PROVINCE_LIST_ITEMS,
    nonRestrictedStateListItems: [],
    zip: 'Postal code',
    zipMask: 'canada-postal-code',
};

export function useLocalization() {
    const { model: layoutViewModel, brand } = useLayoutContext();
    const client = useGraphQL();

    const isAdAstra = brand === Brands.AdAstra;

    USA.company = layoutViewModel?.isRapidCash ? 'Rapid Cash' : 'Speedy Cash';
    if (isAdAstra) {
        USA.customerServicePhone = '1-866-398-2089';
        USA.customerServiceFax = '1-316-771-8880';
        USA.customerServiceEmail = 'Support@AdAstraRecoveryServicesInc.com';
    }

    let currentBrand = USA;

    switch (brand) {
        case Brands.CashMoney:
            currentBrand = CM_CANADA;
            break;
        case Brands.AdAstra:
        case Brands.SpeedyCash:
            let states = STATE_LIST_ITEMS;
            const cachedStates = sessionStorage.getItem("nonRestrictedStates");

            if(cachedStates) {
                states = JSON.parse(cachedStates);
            } else {
                client.getNonRestrictedStates().then((response) => {
                    states = response?.data?.getNonRestrictedStates ?? STATE_LIST_ITEMS;
                    sessionStorage.setItem('nonRestrictedStates', JSON.stringify(states));
                });
            }

            USA.nonRestrictedStateListItems = states
            currentBrand = USA;
            break;
        case Brands.LendDirect:
            currentBrand = LD_CANADA;
            break;
        default:
            break;
    }

    return currentBrand;
}
