import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { Routes } from '../Routes';
import { Brands } from '../Brand';
import { AccessTypes } from '../GraphQL.Default';
import ProtectedRoute from '../middleware/ProtectedRoute';

const LazyLoanApplicationAccount = lazy(() => import('./account/AccountSection'));
const LazyAffiliateAccountSection = lazy(() => import('./account/AffiliateAccountSection'));
const LazyLoanApplicationBusiness = lazy(() => import('./business/businessSection'));
const LazyLoanApplicationPersonal = lazy(() => import('./personal/personal-section'));
const LazyLoanApplicationID = lazy(() => import('./identication/identification-section'));
const LazyLoanApplicationIncome = lazy(() => import('./income/IncomeSection'));
const LazyLoanApplicationIncomeVerificationPending = lazy(() => import('./income-verification-pending/IncomeVerificationPendingSection'));
const LazyLoanApplicationIndex = lazy(() => import('./index'));
const LazyLoanApplicationProvince = lazy(() => import('./province/ProvinceSection'));
const LazyLoanApplicationBank = lazy(() => import('./bank/BankSection'));
const LazyLoanApplicationOptionalPaymentAuthorization = lazy(() => import('./optional-payment-authorization/OptionalPaymentAutorizationSection'));
const LazyLoanApplicationOutOfWallet = lazy(() => import('./out-of-wallet/out-of-wallet-section'));
const LazyLoanApplicationOutOfWalletTesting = lazy(() => import('../out-of-wallet-testing/out-of-wallet-testing-section'));
const LazyLoanApplicationProductSelection = lazy(() => import('./product-selection/ProductSelectionSection'));
const LazyLoanApplicationReviewDisclosures = lazy(() => import('./review-disclosures/review-disclosures-section'));
const LazyLoanApplicationSpouse = lazy(() => import('./spouse/spouse-section'));
const LazyLoanApplicationMilitaryStatus = lazy(() => import('./military-status-section'));
const LazyLoanApplicationCreditFreeze = lazy(() => import('./credit-freeze-section'));
const LazyLoanApplicationCreditLocked = lazy(() => import('./credit-locked-section'));
const LazyLoanApplicationNonMarketLocation = lazy(() => import('./non-market-location-section'));
const LazySelectTerms = lazy(() => import('./select-terms/select-terms-section'));
const LazyLoanApplicationPreLoanAppDisclosures = lazy(() => import('./pre-loan-app-disclosures/pre-loan-app-disclosures-section'));
const LazyLoanApplicationSkPreLoanAppDisclosures = lazy(() => import('./pre-loan-app-disclosures/sk-pre-loan-app-disclosures-section'));
const LazyLoanApplicationPending = lazy(() => import('./pending/PendingSection'));
const LazyLoanApplicationScoring = lazy(() => import('./scoring/ScoringSection'));
const LazyLoanApplicationScoringOutage = lazy(() => import('./scoring/OutageSection'));
const LazyLoanApplicationHost = lazy(() => import('./host/HostSection'));
const LazyLoanApplicationVehicle = lazy(() => import('./vehicle/vehicleSection'));
const LazyLoanApplicationReview = lazy(() => import('./review-application/ReviewApplicationSection'));
const LazyLoanApplicationDocuments = lazy(() => import('./documents/documents-section'));
const LazyLoanApplicationDeniedCM = lazy(() => import('./denied/denied-section.CashMoney'));
const LazyLoanApplicationDeniedSRC = lazy(() => import('./denied/denied-section.SpeedyCash'));
const LazyLoanApplicationProtectionPlan = lazy(() => import('./loan-protection-plan/LoanProtectionPlan'));
const LazyPhoneVerification = lazy(() => import('./phone-verification/phone-verification-section'));
const LazyBankVerification = lazy(() => import('./bank-verification/verification/FlinkVerificationSection'));
const LazyBankVerificationPass = lazy(() => import('./bank-verification/pass/BankVerificationPassSection'));
const LazyBankVerificationFail = lazy(() => import('./bank-verification/fail/BankVerificationFailSection'));
const LazyQuickBankVerification = lazy(() => import('./bank-verification/BankVerificationSection'));
const LazyLoanApplicationIndividualScheduledPayments = lazy(() => import('./individual-scheduled-payments/IndividualScheduledPaymentsSection'));
const LazyPendingMilitaryReview = lazy(() => import('../user/pending-military-review/PendingMilitaryReview'));


export function getLoanApplicationRoutes(brand: Brands) {
    return (
        <>
            <Route element={<ProtectedRoute allowedAccessTypes={[AccessTypes.Application, AccessTypes.TellerAccess]} />}>
                <Route path={Routes.LoanApplication.Province} element={<LazyLoanApplicationProvince />} />
                <Route path={Routes.LoanApplication.Business} element={<LazyLoanApplicationBusiness />} />
                <Route path={Routes.LoanApplication.Personal} element={<LazyLoanApplicationPersonal />} />
                <Route path={Routes.LoanApplication.ID} element={<LazyLoanApplicationID />} />
                <Route path={Routes.LoanApplication.Income} element={<LazyLoanApplicationIncome />} />
                <Route path={Routes.LoanApplication.Bank} element={<LazyLoanApplicationBank />} />
                <Route path={Routes.LoanApplication.Spouse} element={<LazyLoanApplicationSpouse />} />
                <Route path={Routes.LoanApplication.Vehicle} element={<LazyLoanApplicationVehicle />} />
                <Route path={Routes.LoanApplication.MilitaryStatus} element={<LazyLoanApplicationMilitaryStatus />} />
                <Route path={Routes.LoanApplication.CreditFreeze} element={<LazyLoanApplicationCreditFreeze />} />
                <Route path={Routes.LoanApplication.CreditLocked} element={<LazyLoanApplicationCreditLocked />} />
                <Route path={Routes.LoanApplication.NonMarketLocation} element={<LazyLoanApplicationNonMarketLocation />} />
                <Route path={Routes.LoanApplication.Documents} element={<LazyLoanApplicationDocuments />} />
                <Route path={Routes.LoanApplication.Denied}
                    element={
                        brand === Brands.CashMoney
                            ? <LazyLoanApplicationDeniedCM />
                            : <LazyLoanApplicationDeniedSRC />
                    }
                />
                <Route path={Routes.LoanApplication.ReviewDisclosures} element={<LazyLoanApplicationReviewDisclosures />} />
                <Route path={Routes.LoanApplication.OptionalPaymentAuthorization} element={<LazyLoanApplicationOptionalPaymentAuthorization />} />
                <Route path={Routes.LoanApplication.OutOfWallet} element={<LazyLoanApplicationOutOfWallet />} />
                <Route path={Routes.LoanApplication.OutOfWalletTesting} element={<LazyLoanApplicationOutOfWalletTesting />} />
                <Route path={Routes.LoanApplication.Process.Index} element={<LazyLoanApplicationScoring />} />
                <Route path={Routes.LoanApplication.Process.Outage} element={<LazyLoanApplicationScoringOutage />} />
                <Route path={Routes.LoanApplication.ProductSelection} element={<LazyLoanApplicationProductSelection />} />
                <Route path={Routes.LoanApplication.Pending} element={<LazyLoanApplicationPending />} />
                <Route path={Routes.LoanApplication.PreLoanAppDisclosures} element={<LazyLoanApplicationPreLoanAppDisclosures />} />
                <Route path={Routes.LoanApplication.ProtectionPlan} element={<LazyLoanApplicationProtectionPlan />} />
                <Route path={Routes.LoanApplication.SKLoanAppDisclosure} element={<LazyLoanApplicationSkPreLoanAppDisclosures />} />
                <Route path={Routes.LoanApplication.Review} element={<LazyLoanApplicationReview />} />
                <Route path={Routes.LoanApplication.Host} element={<LazyLoanApplicationHost />} />
                <Route path={Routes.LoanApplication.SelectTerms} element={<LazySelectTerms />} />
                <Route path={Routes.LoanApplication.IndividualScheduledPayments} element={<LazyLoanApplicationIndividualScheduledPayments />} />
                <Route path={Routes.LoanApplication.PendingMilitaryReview} element={<LazyPendingMilitaryReview />} />
                <Route path={Routes.LoanApplication.BankVerification.Verification} element={<LazyBankVerification />} />
                <Route path={Routes.LoanApplication.BankVerification.Pass} element={<LazyBankVerificationPass />} />
                <Route path={Routes.LoanApplication.BankVerification.Fail} element={<LazyBankVerificationFail />} />
                <Route path={Routes.LoanApplication.BankVerification.QuickVerification} element={<LazyQuickBankVerification />} />
                <Route path={Routes.LoanApplication.PhoneVerification.Index} element={<LazyPhoneVerification />} />
                <Route path={Routes.LoanApplication.IncomeVerificationPending} element={ <LazyLoanApplicationIncomeVerificationPending/>}/>
            </Route>
            <Route path={Routes.LoanApplication.Index} element={<LazyLoanApplicationIndex />} />
            <Route path={Routes.LoanApplication.Account} element={<LazyLoanApplicationAccount />} />
            <Route path={Routes.LoanApplication.AffiliateAccount} element={<LazyAffiliateAccountSection />} />
        </>
    )
}